<template>
  <div class="container">
    <el-row>
      <el-col :span="8" style="padding:10px" v-for="item of data" :key="item.id">
        <div class="tofu-card" @click="toMain(item.id)">
          <h1>{{item.name}}</h1>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTofuList } from './../api/getway'
import utils from './../utils/utils'
import { ElRow,ElCol } from 'element-plus'
export default {
  components: {
    ElRow,
    ElCol
  },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.getTofuList()
  },
  methods: {
    async getTofuList () {
      const res = await getTofuList({})
      this.data = res.data.permission
      // getTofuList({}).then((res) => {
      //   this.data = res.data.permission
      // })
    },
    toMain (id) {
      this.$router.push({ path: '/welcome', query: { id } })
      setTimeout(() => {
        this.$notify({
          title: '欢迎',
          message: `${utils.timeFix()}，欢迎回来`,
          type: 'success'
        })
      }, 1000)
    }
  }
}
</script>

<style>
.container{
  padding: 20px;
  /* background:url('./../assets/images/bg.png') no-repeat; */
  /* opacity:0.5; */
}
.tofu-card{
  height:200px;
  border:1px solid #e8e8e8;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  line-height: 200px;
  cursor:pointer;
}
.tofu-card:hover {
  background-color: #00a0e9;
}
</style>
